import { combineReducers } from "redux";
// yeh import actioin se kre rhy hai action ka function yeh formfunction


import {  CounterReducer   } from "./CountReducer";
import utilsReducer from "./utilsReducer";


const reducers = combineReducers({
  Counter:CounterReducer,
  utils:utilsReducer
});
export default reducers;

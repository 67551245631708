import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux'
import { baseUrl } from '../../../repositories/baseUrl';
import { openNotificationWithIcon, startloader, stoploader } from '../../../Redux/Action/ActionFunction';
import { Modal } from 'antd';
import axios from 'axios';

export default function DataComp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { confirm } = Modal;
    let Token = localStorage.getItem("token");

    const [AllComplex, setAllComplex] = useState([]);
    const [ComplexId, setComplexId] = useState("All");


    // form state
    const [Id, setId] = useState("");
    const [Day, setDay] = useState("");
    const [Complex, setComplex] = useState("");
    const [Walk_ins, setWalk_ins] = useState("");
    const [Prospective_tenants, setProspective_tenants] = useState("");
    const [Applications, setApplications] = useState("");
    const [Pre_leased, setPre_leased] = useState("");
    const [Readies, setReadies] = useState("");
    const [Orders_done, setOrders_done] = useState("");
    const [Orders_open, setOrders_open] = useState("");
    const [Call_backs, setCall_backs] = useState("");
    const [Vacant, setVacant] = useState("");
    const [Vacant_units, setVacant_units] = useState("");
    const [Rent, setRent] = useState("");
    const [Balance, setBalance] = useState("");
    const [Reason_for_job, setReason_for_job] = useState("");
    // form state

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        UpdateForm()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e, func) => {
        func(e.target.value)
    };

    const [Data, setData] = useState([])

    useEffect(() => {
        getComplex();
        getComplexForms("All");
    }, [])

    const getComplex = () => {
        dispatch(startloader())
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/get/all/complex`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setAllComplex(Data.data);
            } else {
                openNotificationWithIcon("warning", "Opps", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const sendEmail = () => {
        if (ComplexId == "All") {
            openNotificationWithIcon("info", "Opps!", "Please select Complex");
        } else {
            dispatch(startloader())
            const formData = new FormData();
            formData.append("complex_id", ComplexId)
            axios({
                method: 'post',
                url: `${baseUrl.Base_Url}/send/email/job`,
                headers: { Authorization: `Bearer ${Token}` },
                data: formData
            }).then(function (response) {
                dispatch(stoploader())
                const Data = response.data
                if (Data.status) {
                    openNotificationWithIcon("success", "Success!", Data.message);
                } else {
                    openNotificationWithIcon("warning", "Opps", Data.message);
                }
            }).catch((error) => {
                const Err = error.response
                dispatch(stoploader())
                if (Err !== undefined) {
                    if (Err.data.errors) {
                        const Data = Err.data.errors;
                        const ErrList = Object.keys(Data);
                        ErrList.map((e) => {
                            openNotificationWithIcon("warning", "Opps", Data[e][0]);
                        });
                    } else {
                        openNotificationWithIcon("error", "Opps", Err.data.message);
                        if (Err.data.message === "Unauthenticated.") {
                            // dispatch(authLogout());
                            navigate('/employee/login')
                        }
                    }
                } else {
                    openNotificationWithIcon("error", "Opps", error.message);
                }
            });
        }
    }

    // const getForms = () => {
    //     dispatch(startloader())
    //     axios({
    //         method: 'get',
    //         url: `${baseUrl.Base_Url}/view/employee/forms`,
    //         headers: { Authorization: `Bearer ${Token}` },
    //     }).then(function (response) {
    //         dispatch(stoploader())
    //         const Data = response.data
    //         if (Data.status) {
    //             setData(Data.data)
    //         } else {
    //             console.log("Data", Data)
    //             openNotificationWithIcon("warning", "Opps", Data.message);
    //         }
    //     }).catch((error) => {
    //         const Err = error.response
    //         dispatch(stoploader())
    //         if (Err !== undefined) {
    //             if (Err.data.errors) {
    //                 const Data = Err.data.errors;
    //                 const ErrList = Object.keys(Data);
    //                 ErrList.map((e) => {
    //                     openNotificationWithIcon("warning", "Opps", Data[e][0]);
    //                 });
    //             } else {
    //                 openNotificationWithIcon("error", "Opps", Err.data.message);
    //                 if (Err.data.message === "Unauthenticated.") {
    //                     // dispatch(authLogout());
    //                     navigate('/admin/login')
    //                 }
    //             }
    //         } else {
    //             openNotificationWithIcon("error", "Opps", error.message);
    //         }
    //     });
    // }

    const getComplexForms = (e) => {
        dispatch(startloader())
        const formData = new FormData();
        formData.append("complex_id", e)
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/filter/complex/records`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
            } else {
                console.log("Data", Data)
                openNotificationWithIcon("warning", "Opps", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const handleUpdate = (Data) => {
        // console.log("Data", Data);
        setId(Data.id)
        setDay(Data.day)
        setComplex(Data.complex)
        setWalk_ins(Data.walk_ins)
        setProspective_tenants(Data.prospective_tenants)
        setApplications(Data.prospective_tenants)
        setPre_leased(Data.pre_leased)
        setReadies(Data.readies)
        setOrders_done(Data.orders_done)
        setOrders_open(Data.orders_open)
        setCall_backs(Data.call_backs)
        setVacant(Data.vacant)
        setVacant_units(Data.vacant_units)
        setRent(Data.rent)
        setBalance(Data.balance)
        setReason_for_job(Data.reason_for_job)
        showModal()
    }

    const UpdateForm = () => {
        handleCancel()
        dispatch(startloader())
        const formData = new FormData()
        formData.append("id", Id)
        formData.append("day", Day)
        formData.append("walk_ins", Walk_ins)
        formData.append("prospective_tenants", Prospective_tenants)
        formData.append("applications", Applications)
        formData.append("pre_leased", Pre_leased)
        formData.append("readies", Readies)
        formData.append("orders_done", Orders_done)
        formData.append("orders_open", Orders_open)
        formData.append("call_backs", Call_backs)
        formData.append("vacant", Vacant)
        formData.append("vacant_units", Vacant_units)
        formData.append("rent", Rent)
        formData.append("balance", Balance)
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/update/emp/form`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            console.log("Update", Data)
            if (Data.status) {
                setData(Data.data)
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                showModal()
                openNotificationWithIcon("warning", 'Opps!', Data.message);
                setIsModalOpen(true);
            }
        }).catch((error) => {
            showModal()
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const DeleteConfirm = (id) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <span>Are You Sure you want to delete this form?</span>,
            centered: true,
            onOk() {
                DeleteForm(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    const DeleteForm = (id) => {
        dispatch(startloader())
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/del/emp/form/${id}`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            console.log("Delete", Data)
            if (Data.status) {
                setData(Data.data)
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">All Forms</h3>
                        <div className="row breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/admin/forms"}>Forms</Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <div className="content-header-right col-md-6 col-12">
                        <a className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right" href="#" >Add New Ad</a>
                    </div> */}
                </div>

                <div className="content-body">
                    <form className='form'>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <select name="complex" className="form-control"
                                        onChange={(e) => {
                                            getComplexForms(e.target.value);
                                            setComplexId(e.target.value)
                                        }}>
                                        <option value="All">All</option>
                                        {AllComplex.map((e,i) => (
                                            <option key={i} value={e.id}>{e.complex}</option>
                                        ))}
                                        {/* <option value="Clube One/Aarons">Clube One/Aarons</option>
                                        <option value="high">High</option>
                                        <option value="medium">Medium</option> */}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <button className='btn btn-primary mx-1' type='button' onClick={sendEmail}>Email</button>
                                {/* <button className='btn btn-success mx-1'>Download</button> */}
                                {/* <button className='btn btn-secondary mx-1'>Edit</button> */}
                            </div>
                            {/* <div className="col-md-3">
                        </div>
                        <div className="col-md-3">
                        </div> */}
                        </div>
                    </form>
                    {/* Basic Tables start */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card-content collapse show">
                                <MaterialTable
                                    columns={[
                                        // {
                                        //     title: 'Image', field: 'images',
                                        //     render: rowData => (
                                        //         <img src={baseUrl.Image_Url + rowData.images[0].imagepath} style={{ width: 50, borderRadius: '50%' }} />
                                        //     )
                                        // },
                                        { title: 'Day', field: 'day' },
                                        { title: 'Walk Ins', field: 'walk_ins' },
                                        { title: 'Prospects', field: 'prospective_tenants' },
                                        { title: 'Apps', field: 'applications' },
                                        { title: 'Pre lease', field: 'pre_leased' },
                                        { title: 'Make Readies', field: 'readies' },
                                        { title: 'Work Orders', field: 'orders_done' },
                                        { title: 'Open Wo', field: 'orders_open' },
                                        { title: 'Wo Call Backs', field: 'call_backs' },
                                        { title: 'Vacant', field: 'vacant' },
                                        { title: 'New Vacant', field: 'vacant_units' },
                                        { title: 'Delinquents', field: 'rent' },
                                        { title: 'Balances', field: 'balance' },
                                    ]}
                                    data={Data}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Edit',
                                            onClick: (event, rowData) => {
                                                handleUpdate(rowData)
                                            }
                                        }, {
                                            icon: 'delete',
                                            tooltip: 'Delete',
                                            onClick: (event, rowData) => {
                                                DeleteConfirm(rowData.id)
                                            }
                                        }
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1,
                                        exportButton: true,
                                    }}
                                    title=""
                                />
                            </div>
                        </div>
                    </div>
                    {/* Basic Tables end */}
                </div>
            </div>
            <Modal title="Update Form" width={1200} open={isModalOpen} okText={"Update"} onOk={handleOk} onCancel={handleCancel}>
                <form className="form form-horizontal">
                    <div className="form-body">
                        <h4 className="form-section"></h4>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="day">Day</label>
                                    <div className="col-md-9">
                                        {/* <input value={Day} type="text" onChange={(e) => handleInputChange(e, setDay)} id="day" className="form-control border-primary" placeholder="Day" name="day" /> */}
                                        <select value={Day} id="days" onChange={(e) => handleInputChange(e, setDay)} name="interested" className="form-control">
                                            <option value="" selected disabled>Select Day</option>
                                            <option value="Monday">Monday</option>
                                            <option value="Tuesday">Tuesday</option>
                                            <option value="Wednesday">Wednesday</option>
                                            <option value="Thursday">Thursday</option>
                                            <option value="Friday">Friday</option>
                                            <option value="Saturday">Saturday</option>
                                            <option value="Sunday">Sunday</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="walkIns">Walk Ins</label>
                                    <div className="col-md-9">
                                        <input type="number" value={Walk_ins} onChange={(e) => handleInputChange(e, setWalk_ins)} id="walkIns" className="form-control border-primary" placeholder="Walk Ins" name="Walk Ins" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="ProspectiveTenants">Prospective Tenants</label>
                                    <div className="col-md-9">
                                        <input value={Prospective_tenants} onChange={(e) => handleInputChange(e, setProspective_tenants)} className="form-control border-primary" type="number" placeholder="Prospective Tenants" id="ProspectiveTenants" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="Applications">Applications</label>
                                    <div className="col-md-9">
                                        <input value={Applications} onChange={(e) => handleInputChange(e, setApplications)} className="form-control border-primary" type="number" placeholder="Applications" id="Applications" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="PreLeased">Pre Leased</label>
                                    <div className="col-md-9">
                                        <input value={Pre_leased} onChange={(e) => handleInputChange(e, setPre_leased)} className="form-control border-primary" type="number" placeholder="Pre Leased" id="PreLeased" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="readies">Readies</label>
                                    <div className="col-md-9">
                                        <input value={Readies} onChange={(e) => handleInputChange(e, setReadies)} className="form-control border-primary" type="number" placeholder="readies" id="readies" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="orders_done">Orders Done</label>
                                    <div className="col-md-9">
                                        <input value={Orders_done} onChange={(e) => handleInputChange(e, setOrders_done)} className="form-control border-primary" type="number" placeholder="orders Done" id="orders_done" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="Orders_open">Orders Open</label>
                                    <div className="col-md-9">
                                        <input value={Orders_open} onChange={(e) => handleInputChange(e, setOrders_open)} className="form-control border-primary" type="number" placeholder="Orders Open" id="Orders_open" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="call_backs">Call Backs</label>
                                    <div className="col-md-9">
                                        <input value={Call_backs} onChange={(e) => handleInputChange(e, setCall_backs)} className="form-control border-primary" type="number" placeholder="Call Backs" id="call_backs" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="vacant">Vacant</label>
                                    <div className="col-md-9">
                                        <input value={Vacant} onChange={(e) => handleInputChange(e, setVacant)} className="form-control border-primary" type="number" placeholder="Vacant" id="vacant" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="vacant_units">Vacant Units</label>
                                    <div className="col-md-9">
                                        <input value={Vacant_units} onChange={(e) => handleInputChange(e, setVacant_units)} className="form-control border-primary" type="number" placeholder="Vacant Units" id="vacant_units" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="rent">Rent</label>
                                    <div className="col-md-9">
                                        <input value={Rent} onChange={(e) => handleInputChange(e, setRent)} className="form-control border-primary" type="number" placeholder="Rent" id="rent" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row align-items-center">
                                    <label className="col-md-3 label-control" htmlFor="balance">Balance</label>
                                    <div className="col-md-9">
                                        <input value={Balance} onChange={(e) => handleInputChange(e, setBalance)} className="form-control border-primary" type="number" placeholder="Balance" id="balance" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

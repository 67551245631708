import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";
import './App.css';

// Admin
import AdminLogin from './pages/Admin/auth/login';
import AdminDashboard from './pages/Admin/dashboard';
import AdminData from "./pages/Admin/data";
import AdminProfile from "./pages/Admin/profile";
import Error404 from "./pages/Admin/errors/Error404";
import EmployeesData from "./pages/Admin/employees";
import Complex from "./pages/Admin/complex";
import ComplexEmail from "./pages/Admin/complex_email";
import CostumersData from "./pages/Admin/customers";
// Admin

// Employee 
import EmployeeLogin from "./pages/Employee/auth/login";
import EmployeeForm from "./pages/Employee/form";
import EmployeeError404 from "./pages/Employee/errors/Error404";
import EmployeeProfile from "./pages/Employee/profile";
import ExecutiveTeam from "./pages/Admin/executiveTeam";
import BalanceTeam from "./pages/Admin/balanceTeam";
// Employee

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<AdminLogin />} />
          {/* Admin */}
          <Route path='/admin/login' element={<AdminLogin />} />
          <Route path='/admin/dashboard' element={<AdminDashboard />} />
          <Route path='/admin/forms' element={<AdminData />} />
          <Route path='/admin/employees' element={<EmployeesData />} />
          <Route path='/admin/customers' element={<CostumersData />} />
          <Route path='/admin/executiveTeam' element={<ExecutiveTeam />} />
          <Route path='/admin/balanceTeam' element={<BalanceTeam />} />
          <Route path='/admin/complex' element={<Complex />} />
          <Route path='/admin/complex_email' element={<ComplexEmail />} />
          <Route path='/admin/profile' element={<AdminProfile />} />
          <Route path='/admin/*' element={<Error404 isAuth={true} />} />
          {/* Admin */}

          {/* Employee */}
          <Route path='/employee/login' element={<EmployeeLogin />} />
          <Route path='/employee/form' element={<EmployeeForm />} />
          <Route path='/employee/profile' element={<EmployeeProfile />} />
          <Route path='/employee/*' element={<EmployeeError404 />} />
          {/* Employee */}

          <Route path='*' element={<Error404 isAuth={false} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React from 'react'
import { Link } from 'react-router-dom'

const Errors404Comp = ({ isAuth }) => {
    return (
        <div className="app-content content" style={{ marginLeft: "0px" }}>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-md-4 col-10 p-0">
                                <div className="card-header bg-transparent border-0">
                                    <h2 className="error-code text-center mb-2">404</h2>
                                    <h3 className="text-uppercase text-center">Page Not Found !</h3>
                                </div>
                                <div className="card-content">
                                    <div className="row py-2 justify-content-center">
                                        <div className="col-12 col-sm-6 col-md-6">
                                            <Link to={isAuth ? "/admin/dashboard" : "/admin/login"} className="btn btn-primary btn-block"><i className="ft-home" />{isAuth ? " Back to Dashboard" : " Back to Login"}</Link>
                                        </div>
                                        {/* <div className="col-12 col-sm-6 col-md-6">
                                            <a href="#" className="btn btn-danger btn-block"><i className="ft-search" />  Advanced search</a>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent">
                                    <div className="row">
                                        <p className="text-muted text-center col-12 py-1">© 2022 <Link to={"/admin/dashboard"}>Balance On Life </Link>Crafted with <i className="ft-heart pink"> </i>by <a href="https://www.hnhtechsolutions.com/" target="_blank">Hnh Tech Solutions</a></p>
                                        <div className="col-12 text-center">
                                            <a href="#" className="btn btn-social-icon mr-1 mb-1 btn-outline-facebook">
                                                <span className="la la-facebook" />
                                            </a>
                                            <a href="#" className="btn btn-social-icon mr-1 mb-1 btn-outline-twitter">
                                                <span className="la la-twitter" />
                                            </a>
                                            <a href="#" className="btn btn-social-icon mr-1 mb-1 btn-outline-linkedin">
                                                <span className="la la-linkedin font-medium-4" />
                                            </a>
                                            <a href="#" className="btn btn-social-icon mr-1 mb-1 btn-outline-github">
                                                <span className="la la-github font-medium-4" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>

    )
}

export default Errors404Comp
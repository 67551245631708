import { IconButton } from '@material-ui/core'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../../repositories/baseUrl'
import { openNotificationWithIcon, startloader, stoploader } from '../../../Redux/Action/ActionFunction'

export default function EmployeeProfileComp() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const selector = useSelector((state) => state.login);

    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Email, setEmail] = useState("");

    const [ProfileImage, setProfileImage] = useState(null);

    const [SelectProfileImage, setSelectProfileImage] = useState(null)
    const [UpdateProfileImage, setUpdateProfileImage] = useState(null)

    let Token = localStorage.getItem("EmployeeToken");

    useEffect(() => {
        getProfile()
    }, [])

    const getProfile = () => {
        dispatch(startloader())
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/get/employee/profile`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setProfileImage(Data.image_path)
                setFirstName(Data.first_name)
                setLastName(Data.last_name)
                setEmail(Data.email)
            } else {
                console.log("Data", Data)
                openNotificationWithIcon("warning", "Opps", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/employee/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const handleInputChange = (e, func) => {
        func(e.target.value);
    }

    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        // console.log(url)
        reader.onloadend = function (e) {
            setSelectProfileImage(reader.result);
            setUpdateProfileImage(file);
        };
        func(file)
    }

    const UpdateProfile = () => {
        dispatch(startloader())
        const formData = new FormData();
        formData.append("first_name", FirstName)
        formData.append("last_name", LastName)
        if (UpdateProfileImage) {
            formData.append("file", UpdateProfileImage)
        }
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/update/employee/profile`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setProfileImage(Data.data.image_path)
                setFirstName(Data.data.first_name)
                setLastName(Data.data.last_name)
                setEmail(Data.data.email)
                localStorage.setItem("EmployeeObj", JSON.stringify(Data.data));
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/employee/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">Employee Profile</h3>
                        <div className="row breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/employee/form"}>Form</Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={"/employee/profile"}>Profile</Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="horizontal-form-layouts">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-content collpase show">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <input
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        id="icon-button-file"
                                                        type="file"
                                                        onChange={(e) => handleInputFileChange(e, setProfileImage)}
                                                    />
                                                    <label htmlFor="icon-button-file">
                                                        <IconButton
                                                            // color="#414141"
                                                            aria-label="upload picture"
                                                            component="span"
                                                            className="upload_photo_main"
                                                        >
                                                            <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} src={SelectProfileImage ? SelectProfileImage : baseUrl.Image_Url + ProfileImage} className="upload_photo_main" alt="profile" />
                                                        </IconButton>
                                                    </label>
                                                </div>
                                            </div>
                                            <form className="form form-horizontal">
                                                <div className="form-body">
                                                    <h4 className="form-section"><i className="la la-eye" /> About User</h4>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group row align-items-center">
                                                                <label className="col-md-3 label-control mb-0" htmlFor="fname">Fist Name</label>
                                                                <div className="col-md-9">
                                                                    <input value={FirstName} type="text" onChange={(e) => handleInputChange(e, setFirstName)} id="fname" className="form-control border-primary" placeholder="First Name" name="firstname" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group row align-items-center">
                                                                <label className="col-md-3 label-control mb-0" htmlFor="lname">Last Name</label>
                                                                <div className="col-md-9">
                                                                    <input type="text" value={LastName} onChange={(e) => handleInputChange(e, setLastName)} id="lname" className="form-control border-primary" placeholder="Last Name" name="lastname" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h4 className="form-section"><i className="ft-mail" /> Contact Info &amp; Bio</h4>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group row align-items-center">
                                                                <label className="col-md-3 label-control mb-0" htmlFor="email">Email</label>
                                                                <div className="col-md-9">
                                                                    <input value={Email} onChange={(e) => handleInputChange(e, setEmail)} disabled className="form-control border-primary" type="email" placeholder="email" id="email" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-actions right">
                                                    <button type="button" onClick={UpdateProfile} className="btn btn-primary">
                                                        <i className="la la-check-square-o" /> Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>

    )
}

import { Modal } from 'antd';
import axios from 'axios';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { openNotificationWithIcon, startloader, stoploader } from '../../../Redux/Action/ActionFunction';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { baseUrl } from '../../../repositories/baseUrl';

export default function ComplexComp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { confirm } = Modal;
    let Token = localStorage.getItem("token");

    const [Id, setId] = useState("");
    const [Complex, setComplex] = useState("");

    const [ModalTitle, setModalTitle] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (e) => {
        if (e === "Add Complex") {
            emptyStates()
        }
        setIsModalOpen(true);
        setModalTitle(e)
    };

    const handleOk = () => {
        setIsModalOpen(false);
        if (ModalTitle === "Add Complex") {
            AddComplex();
        } else {
            UpdateComplex()
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e, func) => {
        func(e.target.value);
    }

    const [Data, setData] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        dispatch(startloader())
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/get/all/complex`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
            } else {
                openNotificationWithIcon("warning", "Opps", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const emptyStates = () => {
        setComplex("")
    }

    const AddComplex = () => {
        dispatch(startloader())
        const formData = new FormData()
        formData.append("complex", Complex)
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/add/complex`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                emptyStates()
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                showModal("Add Complex")
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            showModal("Add Complex")
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const handleUpdate = (Data) => {
        setComplex(Data.complex)
        setId(Data.id)
        showModal("Edit Complex")
    }

    const UpdateComplex = () => {
        dispatch(startloader())
        const formData = new FormData()
        formData.append("id", Id)
        formData.append("complex", Complex)
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/update/complex`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                emptyStates()
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                showModal("Edit Complex")
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            showModal("Edit Complex")
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const DeleteConfirm = (id) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <span>Are You Sure you want to delete this Complex?</span>,
            centered: true,
            onOk() {
                DeleteComplex(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    const DeleteComplex = (id) => {
        dispatch(startloader())
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/del/complex/${id}`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">All Complex</h3>
                        <div className="row breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/admin/complex"}>Complex</Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <div className="content-header-right col-md-6 col-12">
                        <a className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right" href="#" >Add New Ad</a>
                    </div> */}
                </div>

                <div className="content-body">
                    {/* Basic Tables start */}
                    <div className="row">
                        <div className="col-md-12 text-right mb-1">
                            <button className='btn btn-primary mx-1' onClick={() => showModal("Add Complex")}>Add Complex</button>
                        </div>
                        <div className="col-12">
                            <div className="card-content collapse show">
                                <MaterialTable
                                    columns={[
                                        { title: 'Complex', field: 'complex' },
                                    ]}
                                    data={Data}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Edit',
                                            onClick: (event, rowData) => {
                                                handleUpdate(rowData)
                                            }
                                        },
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete',
                                            onClick: (event, rowData) => {
                                                DeleteConfirm(rowData.id)
                                            }
                                        }
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    title=""
                                />
                            </div>
                        </div>
                    </div>
                    {/* Basic Tables end */}
                </div>
            </div>
            <Modal title={ModalTitle} open={isModalOpen} onOk={handleOk} okText={ModalTitle === "Add Complex" ? "Add" : "Edit"} onCancel={handleCancel}>
                <form className="form form-horizontal" onSubmit={(e) => { e.preventDefault(); handleOk() }}>
                    <div className="form-body">
                        <h4 className="form-section"></h4>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row align-items-center">
                                    <div className="col-md-12">
                                        <input value={Complex} type="text" onChange={(e) => handleInputChange(e, setComplex)} id="complex" className="form-control border-primary" placeholder="Complex" name="complex" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

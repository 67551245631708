import React from 'react'
import BalanceTeamComp from '../../../components/Admin/balanceTeam'
import MasterLayoutAdmin from '../../../layout/Admin/masterLayoutAdmin'

export default function BalanceTeam() {
    return (
        <MasterLayoutAdmin>
            <BalanceTeamComp />
        </MasterLayoutAdmin>
    )
}

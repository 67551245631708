import React from 'react'
import Errors404Comp from '../../../components/Admin/errors/errors_404'

const Error404 = ({ isAuth }) => {
  return (
    <>
      <Errors404Comp isAuth={isAuth} />
    </>
  )
}
export default Error404
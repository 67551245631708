import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import { useDispatch } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { baseUrl } from '../../../repositories/baseUrl';
import { openNotificationWithIcon, startloader, stoploader } from '../../../Redux/Action/ActionFunction';
import { Divider, Image, Modal } from 'antd';
import axios from 'axios';
import { IconButton } from '@material-ui/core';

export default function ExecutiveTeamComp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { confirm } = Modal;
    let Token = localStorage.getItem("token");

    const [Id, setId] = useState("");
    const [Description, setDescription] = useState("");

    const [ProfileImage, setProfileImage] = useState("/app-assets/images/profile.jpg");
    const [SelectProfileImage, setSelectProfileImage] = useState(null)
    const [UpdateProfileImage, setUpdateProfileImage] = useState(null)

    const [ModalTitle, setModalTitle] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (e) => {
        if (e === "Add Executive Member") {
            emptyStates()
        }
        setIsModalOpen(true);
        setModalTitle(e)
    };

    const handleOk = () => {
        setIsModalOpen(false);
        if (ModalTitle === "Add Executive Member") {
            AddCustomer();
        } else {
            UpdateCustomer()
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e, func) => {
        func(e.target.value);
    }

    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        // console.log(url)
        reader.onloadend = function (e) {
            setSelectProfileImage(reader.result);
            setUpdateProfileImage(file);
        };
        func(file)
    }

    const [Data, setData] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        dispatch(startloader())
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/view/executive/team`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
            } else {
                openNotificationWithIcon("warning", "Opps", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const emptyStates = () => {
        setId("")
        setDescription("")
        setProfileImage("/app-assets/images/profile.jpg")
        setSelectProfileImage(null)
        setUpdateProfileImage(null)
    }

    const AddCustomer = () => {
        dispatch(startloader())
        const formData = new FormData()
        formData.append("description", Description)
        if (UpdateProfileImage) {
            formData.append("file", UpdateProfileImage)
        }
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/add/executive/team`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                emptyStates()
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                showModal("Add Executive Member")
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            showModal("Add Executive Member")
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const handleUpdate = (Data) => {
        setId(Data.id)
        setDescription(Data.description)
        setSelectProfileImage(baseUrl.Image_Url + Data.image_path)
        showModal("Edit Executive Member")
    }

    const UpdateCustomer = () => {
        dispatch(startloader())
        const formData = new FormData()
        formData.append("id", Id)
        formData.append("description", Description)
        if (UpdateProfileImage) {
            formData.append("file", UpdateProfileImage)
        }
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/update/executive/team`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                emptyStates()
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                showModal("Edit Executive Member")
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            showModal("Edit Executive Member")
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const DeleteConfirm = (id) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <span>Are You Sure you want to delete this Member?</span>,
            centered: true,
            onOk() {
                DeleteCustomer(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    const DeleteCustomer = (id) => {
        dispatch(startloader())
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/del/executive/team/${id}`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">All Executive Team Members</h3>
                        <div className="row breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/admin/executiveTeam"}>Executive Team</Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <div className="content-header-right col-md-6 col-12">
                        <a className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right" href="#" >Add New Ad</a>
                    </div> */}
                </div>

                <div className="content-body">
                    {/* Basic Tables start */}
                    <div className="row">
                        <div className="col-md-12 text-right mb-1">
                            <button className='btn btn-primary mx-1' onClick={() => showModal("Add Executive Member")}>Add Member</button>
                        </div>
                        <div className="col-12">
                            <div className="card-content collapse show">
                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'Image', field: 'image_path',
                                            render: rowData => (
                                                <Image src={baseUrl.Image_Url + rowData.image_path} style={{ width: 50, height: 50, borderRadius: '50%' }} />
                                            )
                                        },
                                        { title: 'Description', field: 'description' },
                                    ]}
                                    data={Data}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Edit',
                                            onClick: (event, rowData) => {
                                                handleUpdate(rowData)
                                            }
                                        },
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete',
                                            onClick: (event, rowData) => {
                                                DeleteConfirm(rowData.id)
                                            }
                                        }
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    title=""
                                />
                            </div>
                        </div>
                    </div>
                    {/* Basic Tables end */}
                </div>
            </div>
            <Modal title={ModalTitle} width={800} open={isModalOpen} onOk={handleOk} okText={ModalTitle === "Add Executive Member" ? "Add" : "Edit"} onCancel={handleCancel}>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            onChange={(e) => handleInputFileChange(e, setProfileImage)}
                        />
                        <label htmlFor="icon-button-file">
                            <IconButton
                                color="#414141"
                                aria-label="upload picture"
                                component="span"
                                className="upload_photo_main"
                            >
                                <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} src={SelectProfileImage ? SelectProfileImage : ProfileImage} className="upload_photo_main" alt="profile" />
                            </IconButton>
                        </label>
                    </div>
                </div>
                <Divider />
                <form className="form form-vertical" onSubmit={(e) => { e.preventDefault(); handleOk() }}>
                    <div className="form-body">
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label className="label-control" htmlFor="desc">Description</label>
                                <textarea value={Description} type="text" onChange={(e) => handleInputChange(e, setDescription)} id="desc" className="form-control border-primary" placeholder="Description" name="description"></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

import React from 'react'
import ExecutiveTeamComp from '../../../components/Admin/executiveTeam'
import MasterLayoutAdmin from '../../../layout/Admin/masterLayoutAdmin'

export default function ExecutiveTeam() {
    return (
        <MasterLayoutAdmin>
            <ExecutiveTeamComp/>
        </MasterLayoutAdmin>
    )
}

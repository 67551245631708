import axios from 'axios';
import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { openNotificationWithIcon, startloader, stoploader } from "../../Redux/Action/ActionFunction";
import { baseUrl } from '../../repositories/baseUrl';

const UserHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [Togglebtn, setTogglebtn] = useState(false)
    const [Togglemobile, setTogglemobile] = useState(false)
    const [Profilebtn, setProfilebtn] = useState(false)
    let Token = localStorage.getItem("EmployeeToken");
    let UserObj = JSON.parse(localStorage.getItem("EmployeeObj"));

    const togglefunction = () => {
        setTogglebtn(!Togglebtn)
        // setIsHovering(isHovering);
        console.log("value of toggle", Togglebtn)
        if (Togglebtn == false) {
            document.body.classList.add(
                "menu-collapsed"
            );

            document.body.classList.remove(
                "menu-expanded"
            );
        }
        else {
            document.body.classList.add(
                "menu-expanded"
            );
            document.body.classList.remove(
                "menu-collapsed"
            );
        }
    }
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };


    const ahmed = () => {
        // alert("hello balohc")

        // open
        // vertical-layout 2-columns fixed-navbar vertical-overlay-menu pace-done menu-open

        // close
        // vertical-layout 2-columns fixed-navbar vertical-overlay-menu pace-done menu-hide
        // vertical-layout 2-columns fixed-navbar vertical-overlay-menu pace-done menu-hide
        setTogglemobile(!Togglemobile)
        if (Togglemobile == false) {
            document.body.classList.add(
                "vertical-layout", "2-columns", "fixed-navbar", "vertical-overlay-menu", "pace-done", "menu-hide"
            );
            document.body.classList.remove(
                "vertical-menu-modern", "menu-expanded", "menu-open"
            );
        }
        else {
            document.body.classList.add(


                "menu-open"
                // vertical-layout 2-columns fixed-navbar vertical-overlay-menu pace-done menu-open
            );


            document.body.classList.remove(
                "menu-hide"

            );

        }


    }

    const profilebtnfun = () => {
        setProfilebtn(!Profilebtn)
    }

    const handleLogout = () => {
        dispatch(startloader());
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/employee/logout`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                localStorage.removeItem("EmployeeToken")
                localStorage.removeItem("EmployeeObj")
                openNotificationWithIcon("success", 'Good bye!', Data.message);
                navigate("/employee/login")
            } else {
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.data) {
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        navigate('/employee/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    return (
        <>
            <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-dark navbar-shadow">
                <div className="navbar-wrapper">
                    {/* working */}
                    {/* <div className="navbar-header expanded" > */}
                    <div className={isHovering ? 'navbar-header expanded  ' : 'navbar-header '}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                    >
                        <ul className="nav navbar-nav flex-row">
                            <li className="nav-item mobile-menu d-md-none mr-auto">
                                <a onClick={ahmed} className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                                    <i className="ft-menu font-large-1" />
                                </a>
                            </li>
                            <li className="nav-item mr-auto">
                                <Link to={"/employee/form"} className="navbar-brand">
                                    <img className="brand-logo" alt="modern admin logo" src="../../../app-assets/images/logo/icon.png" />
                                    <h3 className="brand-text">Balance on Life</h3>
                                </Link>
                            </li>
                            <li className="nav-item d-none d-md-block float-right">
                                <a className="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                                    <i className={Togglebtn ? "toggle-icon font-medium-3 white ft-toggle-left" : "toggle-icon ft-toggle-right font-medium-3 white"} onClick={togglefunction} data-ticon="ft-toggle-right" />
                                </a>
                            </li>
                            <li className="nav-item d-md-none">
                                <a className="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"><i className="la la-ellipsis-v" /></a>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar-container content">
                        <div className="collapse navbar-collapse" id="navbar-mobile">
                            <ul className="nav navbar-nav mr-auto float-left">
                                <li className="nav-item d-none d-md-block"><a className="nav-link nav-link-expand" href="#"><i className="ficon ft-maximize" /></a></li>
                            </ul>
                            <ul className="nav navbar-nav float-right">
                                <li className={Profilebtn ? "dropdown dropdown-user nav-item show " : "dropdown dropdown-user nav-item"} onClick={profilebtnfun}>
                                    <a className="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                        <span className="mr-1">Hello,
                                            <span className="user-name text-bold-700 text-capitalize">{UserObj.first_name}</span>
                                            <span className="user-name text-bold-700 text-capitalize">{UserObj.last_name}</span>
                                        </span>
                                        <span className="avatar avatar-online">
                                            <img src={baseUrl.Image_Url + UserObj.image_path} alt="avatar" style={{ width: "30px", height: "30px" }} /><i /></span>
                                    </a>
                                    <div className={Profilebtn ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
                                        <Link className="dropdown-item" to={"/employee/profile"}><i className="ft-user" />Profile</Link>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item" onClick={handleLogout}><i className="ft-power" /> Logout</a>
                                    </div>
                                </li>
                                {/* <li className="dropdown dropdown-language nav-item"><a className="dropdown-toggle nav-link" id="dropdown-flag" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="flag-icon flag-icon-gb" /><span className="selected-language" /></a>
                                    <div className="dropdown-menu" aria-labelledby="dropdown-flag"><a className="dropdown-item" href="#"><i className="flag-icon flag-icon-gb" /> English</a>
                                        <a className="dropdown-item" href="#"><i className="flag-icon flag-icon-fr" /> French</a>
                                        <a className="dropdown-item" href="#"><i className="flag-icon flag-icon-cn" /> Chinese</a>
                                        <a className="dropdown-item" href="#"><i className="flag-icon flag-icon-de" /> German</a>
                                    </div>
                                </li>
                                <li className="dropdown dropdown-notification nav-item">
                                    <a className="nav-link nav-link-label" href="#" data-toggle="dropdown"><i className="ficon ft-bell" />
                                        <span className="badge badge-pill badge-default badge-danger badge-default badge-up badge-glow">5</span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                        <li className="dropdown-menu-header">
                                            <h6 className="dropdown-header m-0">
                                                <span className="grey darken-2">Notifications</span>
                                            </h6>
                                            <span className="notification-tag badge badge-default badge-danger float-right m-0">5 New</span>
                                        </li>
                                        <li className="scrollable-container media-list w-100">
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center"><i className="ft-plus-square icon-bg-circle bg-cyan" /></div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">You have new order!</h6>
                                                        <p className="notification-text font-small-3 text-muted">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">30 minutes ago</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center"><i className="ft-download-cloud icon-bg-circle bg-red bg-darken-1" /></div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading red darken-1">99% Server load</h6>
                                                        <p className="notification-text font-small-3 text-muted">Aliquam tincidunt mauris eu risus.</p>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Five hour ago</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center"><i className="ft-alert-triangle icon-bg-circle bg-yellow bg-darken-3" /></div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading yellow darken-3">Warning notifixation</h6>
                                                        <p className="notification-text font-small-3 text-muted">Vestibulum auctor dapibus neque.</p>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Today</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center"><i className="ft-check-circle icon-bg-circle bg-cyan" /></div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Complete the task</h6>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Last week</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center"><i className="ft-file icon-bg-circle bg-teal" /></div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Generate monthly report</h6>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Last month</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="dropdown-menu-footer"><a className="dropdown-item text-muted text-center" href="javascript:void(0)">Read all notifications</a></li>
                                    </ul>
                                </li>
                                <li className="dropdown dropdown-notification nav-item">
                                    <a className="nav-link nav-link-label" href="#" data-toggle="dropdown"><i className="ficon ft-mail">           </i></a>
                                    <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                        <li className="dropdown-menu-header">
                                            <h6 className="dropdown-header m-0">
                                                <span className="grey darken-2">Messages</span>
                                            </h6>
                                            <span className="notification-tag badge badge-default badge-warning float-right m-0">4 New</span>
                                        </li>
                                        <li className="scrollable-container media-list w-100">
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <span className="avatar avatar-sm avatar-online rounded-circle">
                                                            <img src="../../../app-assets/images/portrait/small/avatar-s-19.png" alt="avatar" /><i /></span>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Margaret Govan</h6>
                                                        <p className="notification-text font-small-3 text-muted">I like your portfolio, let's start.</p>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Today</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <span className="avatar avatar-sm avatar-busy rounded-circle">
                                                            <img src="../../../app-assets/images/portrait/small/avatar-s-2.png" alt="avatar" /><i /></span>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Bret Lezama</h6>
                                                        <p className="notification-text font-small-3 text-muted">I have seen your work, there is</p>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Tuesday</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <span className="avatar avatar-sm avatar-online rounded-circle">
                                                            <img src="../../../app-assets/images/portrait/small/avatar-s-3.png" alt="avatar" /><i /></span>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Carie Berra</h6>
                                                        <p className="notification-text font-small-3 text-muted">Can we have call in this week ?</p>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Friday</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <span className="avatar avatar-sm avatar-away rounded-circle">
                                                            <img src="../../../app-assets/images/portrait/small/avatar-s-6.png" alt="avatar" /><i /></span>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Eric Alsobrook</h6>
                                                        <p className="notification-text font-small-3 text-muted">We have project party this saturday.</p>
                                                        <small>
                                                            <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">last month</time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="dropdown-menu-footer"><a className="dropdown-item text-muted text-center" href="javascript:void(0)">Read all messages</a></li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            {/* working */}
            {/* <div className={ "main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" } data-scroll-to-active="true"> */}
            {/* <div className={Togglebtn ? "main-menu menu-fixed menu-dark menu-accordion menu-shadow" : "main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" } data-scroll-to-active="true"> */}
            <div className={isHovering ? 'main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded ' : 'main-menu menu-fixed menu-dark menu-accordion menu-shadow  '}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                data-scroll-to-active="true">
                <div className="main-menu-content">
                    <Scrollbars style={{ maxWidth: 260, height: "90vh" }}>
                        <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                            {/* <li className={mystate ? " nav-item has-sub open " : " nav-item has-sub "}  >
                                <a onClick={() => dispatch(navbartoggle(!mystate))} >

                                    <i className="la la-home" />

                                    <span className="menu-title" data-i18n="nav.dash.main">Dashboard</span>
                                    <span className="badge badge badge-info badge-pill float-right mr-2">3 </span>
                                </a>
                                <ul className="menu-content">
                                    <li className={mystate ? " is-shown " : " "} ><Link className="menu-item" to="/dashboardecommerce" >eCommerce</Link>
                                    </li>
                                    <li className={mystate ? " is-shown " : " "} ><Link className="menu-item" to="/dashboardcrypto" >Crypto</Link>
                                    </li>
                                    <li className={mystate ? " is-shown " : " "} ><Link className="menu-item" to="/dashboardsales" >Sales</Link>
                                    </li>
                                </ul>
                            </li> */}
                            <li className="nav-item" ><NavLink to="/employee/form" className={({ isActive }) => isActive ? "active" : undefined}><i className="la la-home" /><span className="menu-title">Form</span></NavLink>
                            </li>
                            <li className=" navigation-header">
                                <span data-i18n="nav.category.layouts">Pages</span><i className="la la-ellipsis-h ft-minus" data-toggle="tooltip" data-placement="right" data-original-title="Pages" />
                            </li>
                            <li className="nav-item" ><NavLink to="/employee/profile" className={({ isActive }) => isActive ? "active" : undefined}><i className="la la-user" /><span className="menu-title" data-i18n="nav.scrumboard.main">Profile</span></NavLink>
                            </li>
                        </ul>
                    </Scrollbars>
                </div>
            </div>

        </>
    )
}

export default UserHeader
import React from 'react'
import EmpoyeesComp from '../../../components/Admin/employees'
import MasterLayoutAdmin from '../../../layout/Admin/masterLayoutAdmin'

export default function EmployeesData() {
    return (
        <MasterLayoutAdmin>
            <EmpoyeesComp />
        </MasterLayoutAdmin>
    )
}

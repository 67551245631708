import React from 'react'
import AdminDashboardComp from '../../../components/Admin/dashboard'
import MasterLayoutAdmin from '../../../layout/Admin/masterLayoutAdmin'

const AdminDashboard = () => {
    return (
        <>
            <MasterLayoutAdmin>
                <AdminDashboardComp />
            </MasterLayoutAdmin>
        </>
    )
}

export default AdminDashboard
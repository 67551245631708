import { notification } from "antd";

export const NAVBAR = "NAVBAR"
export const STARTLOADER = "STARTLOADER";
export const STOPLOADER = "STOPLOADER";

export const startloader = () => ({
  type: STARTLOADER
});

export const stoploader = () => ({
  type: STOPLOADER
});

export const navbartoggle = (abc) => {
  console.log("abc is ==>", abc)
  return {
    type: NAVBAR,
    payload: abc
  };
};


export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};
import React from 'react'
import LoginComp from '../../../components/Admin/auth/login'

const AdminLogin = () => {
  return (
    <div>
      <LoginComp />
    </div>
  )
}

export default AdminLogin
import { Checkbox, Col, Divider, Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openNotificationWithIcon, startloader, stoploader } from '../../../Redux/Action/ActionFunction';
import { baseUrl } from '../../../repositories/baseUrl';


const CheckboxGroup = Checkbox.Group;
const plainOptions = [
    "Coordinate day with maintenance personal",
    "Post to Craig's List, Facebook and any other publications we use at your location",
    "Check all phone messages and take appropriate action on all items",
    "Check for overdue rent. This includes any people that have setup arrangements to pay. Take action",
    "Call on all applications, call and re-call all references that didn’t get back to you the previous day.",
    "A decision of yes or no has been made on all applications within 48 hours",
    "Post or verify any notice (pest control, lease violations and evictions) are posted",
    "Verify Office, must be vacuumed, dusted and wiped down and restroom cleaned",
    "Verify Pool, all trash must be picked up, must make sure chemicals have been adjusted for the day",
    "Verify Laundry, must be swept mopped and machines wiped down",
    'Verify any invoices are done and submit to "corporate"',
    "Check any cleaning, carpet cleanings, maintenance checklists and any outside contractors.",
    "Check on any grounds keepers, trash picker uppers, lawn guys and anyone else that did work for us that day",
    "Deposit any money collected throughout the day.",
    "Check for Trash, Furniture and Unsightly items around Dumpster, common areas and visual private areas",
    "Check that no Satellite dishes have been put up on any structure (roof, balcony, fence, etc.)",
    "Check that all cars are properly parked and appear to be in running condition (flats, broken windows, etc.)",
    "Check any common areas for visual vandalism",
    "Check empty units for any water leaks inside and verify they are locked",
    "Check for broken windows, Blinds and Screens",
    "Check for unauthorized animals (barking as you go by a door or cat food in common areas)",
    "Look for shingles that have blown off or any general damage",
    "Attempt to visually identify any safety hazard with stairs or equipment.",
    "Check for any broken fence pickets",
];
const defaultCheckedList = ['Coordinate day with maintenance personal'];


export default function EmployeeFormComp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let Token = localStorage.getItem("EmployeeToken");

    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    const [AllComplex, setAllComplex] = useState([]);

    // form state
    const [Day, setDay] = useState("");
    const [Complex, setComplex] = useState("");
    const [Walk_ins, setWalk_ins] = useState("");
    const [Prospective_tenants, setProspective_tenants] = useState("");
    const [Applications, setApplications] = useState("");
    const [Pre_leased, setPre_leased] = useState("");
    const [Readies, setReadies] = useState("");
    const [Orders_done, setOrders_done] = useState("");
    const [Orders_open, setOrders_open] = useState("");
    const [Call_backs, setCall_backs] = useState("");
    const [Vacant, setVacant] = useState("");
    const [Vacant_units, setVacant_units] = useState("");
    const [Rent, setRent] = useState("");
    const [Balance, setBalance] = useState("");
    const [Reason_for_job, setReason_for_job] = useState("");
    // form state

    useEffect(() => {
        let Token = localStorage.getItem("EmployeeToken");
        getComplex(Token)
    }, [])

    const onChange = (list) => {
        console.log("list", list)
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const handleInputChange = (e, func) => {
        func(e.target.value);
    }

    const getComplex = (token) => {
        dispatch(startloader())
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/get/emp/all/complex`,
            headers: { Authorization: `Bearer ${token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setAllComplex(Data.data)
            } else {
                openNotificationWithIcon("warning", "Opps", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/employee/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }


    const SubmitForm = () => {
        dispatch(startloader())
        const formData = new FormData();
        formData.append("day", Day)
        formData.append("complex", Complex)
        formData.append("walk_ins", Walk_ins)
        formData.append("prospective_tenants", Prospective_tenants)
        formData.append("applications", Applications)
        formData.append("pre_leased", Pre_leased)
        formData.append("readies", Readies)
        formData.append("orders_done", Orders_done)
        formData.append("orders_open", Orders_open)
        formData.append("call_backs", Call_backs)
        formData.append("vacant", Vacant)
        formData.append("vacant_units", Vacant_units)
        formData.append("rent", Rent)
        formData.append("balance", Balance)
        formData.append("reason_for_job", Reason_for_job)
        checkedList.map((e) => {
            formData.append("options[]", e)
        })
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/employee/store/form`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/employee/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">Form</h3>
                        <div className="row breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item"><Link to="/">Dashboard</Link>
                                    </li> */}
                                    <li className="breadcrumb-item"><a href="#">Form</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <div className="content-header-right col-md-6 col-12">
                        <a className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right" href="#" >Add New Ad</a>
                    </div> */}
                </div>

                <div className="content-body">
                    {/* <h1 className='text-center'>Managers checklist</h1> */}
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <h4 className="form-section"><i className="la la-user" /> Managers checklist</h4>
                            <div className="form-group row align-items-center justify-content-center">
                                <label className="label-control" htmlFor="days">Select Day: </label>
                                <div className="col-md-4">
                                    <select id="days" onChange={(e) => handleInputChange(e, setDay)} name="interested" className="form-control">
                                        <option value="" selected disabled>Select Day</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                        <option value="Sunday">Sunday</option>
                                    </select>
                                </div>
                                <label className="label-control" htmlFor="complex">Select Complex: </label>
                                <div className="col-md-4">
                                    <select id="complex" onChange={(e) => handleInputChange(e, setComplex)} name="interested" className="form-control">
                                        <option value="" selected disabled>Select Complex</option>
                                        {AllComplex.map((e) => (
                                            <option value={e.id} key={e.id}>{e.complex}</option>
                                        ))}
                                        {/* <option value="development">development</option>
                                        <option value="illustration">illustration</option>
                                        <option value="branding">branding</option>
                                        <option value="video">video</option> */}
                                    </select>
                                </div>
                            </div>
                            <h4 className="form-section"><i className="ft-list" /> Please select from the following:</h4>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                Check all
                            </Checkbox>
                            <Divider />
                            {/* <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} /> */}
                            <Checkbox.Group
                                style={{
                                    width: '100%',
                                }}
                                value={checkedList}
                                onChange={onChange}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Checkbox value="Coordinate day with maintenance personal">Coordinate day with maintenance personal</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Post to Craig's List, Facebook and any other publications we use at your location">Post to Craig's List, Facebook and any other publications we use at your location</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check all phone messages and take appropriate action on all items">Check all phone messages and take appropriate action on all items</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check for overdue rent. This includes any people that have setup arrangements to pay. Take action">Check for overdue rent. This includes any people that have setup arrangements to pay. Take action</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Call on all applications, call and re-call all references that didn’t get back to you the previous day.">Call on all applications, call and re-call all references that didn’t get back to you the previous day.</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="A decision of yes or no has been made on all applications within 48 hours">A decision of yes or no has been made on all applications within 48 hours</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Post or verify any notice (pest control, lease violations and evictions) are posted">Post or verify any notice (pest control, lease violations and evictions) are posted</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Verify Office, must be vacuumed, dusted and wiped down and restroom cleaned">Verify Office, must be vacuumed, dusted and wiped down and restroom cleaned</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Verify Pool, all trash must be picked up, must make sure chemicals have been adjusted for the day">Verify Pool, all trash must be picked up, must make sure chemicals have been adjusted for the day</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Verify Laundry, must be swept mopped and machines wiped down">Verify Laundry, must be swept mopped and machines wiped down</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value='Verify any invoices are done and submit to "corporate"'>Verify any invoices are done and submit to "corporate"</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check any cleaning, carpet cleanings, maintenance checklists and any outside contractors.">Check any cleaning, carpet cleanings, maintenance checklists and any outside contractors.</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check on any grounds keepers, trash picker uppers, lawn guys and anyone else that did work for us that day">Check on any grounds keepers, trash picker uppers, lawn guys and anyone else that did work for us that day</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Deposit any money collected throughout the day.">Deposit any money collected throughout the day.</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check for Trash, Furniture and Unsightly items around Dumpster, common areas and visual private areas">Check for Trash, Furniture and Unsightly items around Dumpster, common areas and visual private areas</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check that no Satellite dishes have been put up on any structure (roof, balcony, fence, etc.)">Check that no Satellite dishes have been put up on any structure (roof, balcony, fence, etc.)</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check that all cars are properly parked and appear to be in running condition (flats, broken windows, etc.)">Check that all cars are properly parked and appear to be in running condition (flats, broken windows, etc.)</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check any common areas for visual vandalism">Check any common areas for visual vandalism</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check empty units for any water leaks inside and verify they are locked">Check empty units for any water leaks inside and verify they are locked</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check for broken windows, Blinds and Screens">Check for broken windows, Blinds and Screens</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check for unauthorized animals (barking as you go by a door or cat food in common areas)">Check for unauthorized animals (barking as you go by a door or cat food in common areas)</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Look for shingles that have blown off or any general damage">Look for shingles that have blown off or any general damage</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Attempt to visually identify any safety hazard with stairs or equipment.">Attempt to visually identify any safety hazard with stairs or equipment.</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="Check for any broken fence pickets">Check for any broken fence pickets</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                            <Divider />
                            <h4 className="form-section"><i className="ft-list" /> Please fill the form below:</h4>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many walk-ins did you get today?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setWalk_ins)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many new prospective tenants called today?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setProspective_tenants)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many applications did you get today?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setApplications)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many Pre-leased?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setPre_leased)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many make readies got done today?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setReadies)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many work orders got done today?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setOrders_done)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many Work Orders do you have open?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setOrders_open)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many Work Order "Call Backs" did we get today?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setCall_backs)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many vacant do you have today?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setVacant)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many new vacant units did you get today?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setVacant_units)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How many units have delinquent rent?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setRent)} placeholder="" name="" />
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label className="col-md-5 label-control text-left">How much is your outstanding balance?</label>
                                <div className="col-md-2">
                                    <input type="number" className="form-control" onChange={(e) => handleInputChange(e, setBalance)} placeholder="" name="" />
                                </div>
                            </div>
                            <h4 className="form-section"><i className="ft-list" />Is there any reason you could not complete your job today?</h4>
                            <div className="form-group row last">
                                <div className="col-md-12">
                                    <textarea rows={5} className="form-control" onChange={(e) => handleInputChange(e, setReason_for_job)} name="ereason" placeholder="Write Reason" defaultValue={""} />
                                </div>
                            </div>
                        </div>
                        <div className="form-actions center">
                            {/* <button type="button" className="btn btn-warning mr-1">
                                <i className="ft-x" /> Cancel
                            </button> */}
                            <button type="button" onClick={SubmitForm} className="btn btn-primary">
                                Submit Totals
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import AdminProfileComp from '../../../components/Admin/profile'
import MasterLayoutAdmin from '../../../layout/Admin/masterLayoutAdmin'

export default function AdminProfile() {
    return (
        <MasterLayoutAdmin>
            <AdminProfileComp />
        </MasterLayoutAdmin>
    )
}

import React from 'react';
import { Loader } from 'react-overlay-loader';
import { useSelector } from 'react-redux';
import UserFooter from './UserFooter';
import UserHeader from './UserHeader';
const MasterLayoutUser = ({ children }) => {
    const loader = useSelector(state => state.utils.loader);
    return (
        <>
            {loader ? <Loader fullPage loading /> : null}
            <UserHeader />
            {children}
            <UserFooter />
        </>
    )
};
export default MasterLayoutUser;
import React from 'react'
import EmployeeFormComp from '../../../components/Employee/form'
import MasterLayoutUser from '../../../layout/Employee/masterLayoutUser'

export default function EmployeeForm() {
    return (
        <MasterLayoutUser>
            <EmployeeFormComp />
        </MasterLayoutUser>
    )
}

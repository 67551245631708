import React from 'react'
import EmployeeProfileComp from '../../../components/Employee/profile'
import MasterLayoutUser from '../../../layout/Employee/masterLayoutUser'

export default function EmployeeProfile() {
    return (
        <MasterLayoutUser>
            <EmployeeProfileComp />
        </MasterLayoutUser>
    )
}

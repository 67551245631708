import React from 'react';
import { Loader } from 'react-overlay-loader';
import { useSelector } from 'react-redux';
import AdminHeader from './AdminHeader';
import AdminFooter from './AdminFooter';

const MasterLayoutAdmin = ({ children }) => {
    const loader = useSelector(state => state.utils.loader);
    return (
        <>
            {loader ? <Loader fullPage loading /> : null}
            <AdminHeader />
            {children}
            <AdminFooter />
        </>
    )
};
export default MasterLayoutAdmin;
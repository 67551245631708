import React from 'react'
import ComplexEmailComp from '../../../components/Admin/complex_email'
import MasterLayoutAdmin from '../../../layout/Admin/masterLayoutAdmin'

export default function ComplexEmail() {
    return (
        <MasterLayoutAdmin>
            <ComplexEmailComp />
        </MasterLayoutAdmin>
    )
}

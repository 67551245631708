import { Modal } from 'antd';
import axios from 'axios';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { openNotificationWithIcon, startloader, stoploader } from '../../../Redux/Action/ActionFunction';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { baseUrl } from '../../../repositories/baseUrl';

export default function ComplexEmailComp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { confirm } = Modal;
    let Token = localStorage.getItem("token");

    const [Id, setId] = useState("");
    const [ComplexId, setComplexId] = useState("");
    const [Email, setEmail] = useState("");

    const [ModalTitle, setModalTitle] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (e) => {
        if (e === "Add Email") {
            emptyStates()
        }
        setIsModalOpen(true);
        setModalTitle(e)
    };

    const handleOk = () => {
        setIsModalOpen(false);
        if (ModalTitle === "Add Email") {
            AddEmail();
        } else {
            UpdateEmail()
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleInputChange = (e, func) => {
        func(e.target.value);
    }

    const [Data, setData] = useState([])
    const [AllComplex, setAllComplex] = useState([])

    useEffect(() => {
        getData()
        getComplex();
    }, [])

    const getComplex = () => {
        // dispatch(startloader())
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/get/all/complex`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            // dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setAllComplex(Data.data)
            } else {
                openNotificationWithIcon("warning", "Opps", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            // dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const getData = () => {
        dispatch(startloader())
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/get/all/complex/emails`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
            } else {
                openNotificationWithIcon("warning", "Opps", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const emptyStates = () => {
        setEmail("")
    }

    const AddEmail = () => {
        dispatch(startloader())
        const formData = new FormData()
        formData.append("complex_id", ComplexId)
        formData.append("email", Email)
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/add/complex/emails`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                emptyStates()
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                showModal("Add Email")
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            showModal("Add Email")
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const handleUpdate = (Data) => {
        setEmail(Data.email)
        setId(Data.id)
        setComplexId(Data.complex_id)
        showModal("Edit Email")
    }

    const UpdateEmail = () => {
        dispatch(startloader())
        const formData = new FormData()
        formData.append("id", Id)
        formData.append("email", Email)
        formData.append("complex_id", ComplexId)
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/update/complex/email`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                emptyStates()
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                showModal("Edit Email")
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            showModal("Edit Email")
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    const DeleteConfirm = (id) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <span>Are You Sure you want to delete this Email?</span>,
            centered: true,
            onOk() {
                DeleteEmail(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    const DeleteEmail = (id) => {
        dispatch(startloader())
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/del/complex/email/${id}`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                setData(Data.data)
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/admin/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">All Complex Emails</h3>
                        <div className="row breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/admin/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/admin/complex_email"}>Email</Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <div className="content-header-right col-md-6 col-12">
                        <a className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right" href="#" >Add New Ad</a>
                    </div> */}
                </div>

                <div className="content-body">
                    {/* Basic Tables start */}
                    <div className="row">
                        <div className="col-md-12 text-right mb-1">
                            <button className='btn btn-primary mx-1' onClick={() => showModal("Add Email")}>Add Email</button>
                        </div>
                        <div className="col-12">
                            <div className="card-content collapse show">
                                <MaterialTable
                                    columns={[
                                        { title: 'Email', field: 'email' },
                                        {
                                            title: 'Complex', field: 'complex_id',
                                            render: rowData => (
                                                <p>{rowData.complex.complex}</p>
                                            )
                                        },
                                    ]}
                                    data={Data}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Edit',
                                            onClick: (event, rowData) => {
                                                handleUpdate(rowData)
                                            }
                                        },
                                        {
                                            icon: 'delete',
                                            tooltip: 'Delete',
                                            onClick: (event, rowData) => {
                                                DeleteConfirm(rowData.id)
                                            }
                                        }
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}
                                    title=""
                                />
                            </div>
                        </div>
                    </div>
                    {/* Basic Tables end */}
                </div>
            </div>
            <Modal title={ModalTitle} open={isModalOpen} onOk={handleOk} okText={ModalTitle === "Add Email" ? "Add" : "Edit"} onCancel={handleCancel}>
                <form className="form form-horizontal" onSubmit={(e) => { e.preventDefault(); handleOk() }}>
                    <div className="form-body">
                        <h4 className="form-section"></h4>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row align-items-center">
                                    <div className="col-md-6">
                                        <select name="complex" value={ComplexId} className="form-control"
                                            onChange={(e) => setComplexId(e.target.value)}>
                                            {AllComplex.map((e) => (
                                                <option value={e.id}>{e.complex}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <input value={Email} type="text" onChange={(e) => handleInputChange(e, setEmail)} id="complex" className="form-control border-primary" placeholder="Email" name="complex" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { openNotificationWithIcon, startloader, stoploader } from '../../../Redux/Action/ActionFunction';
import { baseUrl } from '../../../repositories/baseUrl';
import { Loader } from 'react-overlay-loader';
import { useSelector } from 'react-redux';

const EmployeeLoginComp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector(state => state.utils.loader);
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")

    const handleLogin = () => {
        // dispatch(authLogin());
        navigate('/employee/form')
    }

    const Enter = (e) => {
        if (e.keyCode === 13) {
            onFinish();
        }
    }

    const handleInputChange = (e, func) => {
        func(e.target.value);
    }

    const onFinish = () => {
        dispatch(startloader());
        const formData = new FormData();
        formData.append('email', Email);
        formData.append('password', Password);
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/employee/login`,
            data: formData,
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.status) {
                localStorage.setItem("EmployeeToken", Data.token);
                localStorage.setItem("EmployeeObj", JSON.stringify(Data.data));
                handleLogin();
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
            } else {
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.errors) {
                    const Data = Err.data.errors;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        navigate('/employee/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    return (
        <div className="app-content content" style={{ marginLeft: 0 }}>
            {loader ? <Loader fullPage loading /> : null}
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-md-4 col-10 box-shadow-2 p-0">
                                <div className="card border-grey border-lighten-3 m-0">
                                    <div className="card-header border-0">
                                        <div className="card-title text-center">
                                            <div className="p-1">
                                                <img src="../../../app-assets/images/logo/logo.png" alt="branding logo" className='img-fluid' />
                                            </div>
                                        </div>
                                        {/* <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                            <span>Easily Using</span>
                                        </h6> */}
                                    </div>
                                    <div className="card-content">
                                        {/* <div className="card-body pt-0 text-center">
                                            <a href="#" className="btn btn-social mb-1 mr-1 btn-outline-facebook">
                                                <span className="la la-facebook" />
                                                <span className="px-1">facebook</span>
                                            </a>
                                            <a href="#" className="btn btn-social mb-1 mr-1 btn-outline-google">
                                                <span className="la la-google-plus font-medium-4" />
                                                <span className="px-1">google</span>
                                            </a>
                                        </div> */}
                                        <p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2">
                                            <span>Employee Login</span>
                                        </p>
                                        <div className="card-body pt-0">
                                            <form className="form-horizontal">
                                                <fieldset className="form-group floating-label-form-group">
                                                    <label htmlFor="email">Your Email</label>
                                                    <input type="email" className="form-control" id="email" onChange={(e) => handleInputChange(e, setEmail)} onKeyDown={(e) => Enter(e)} placeholder="Your Email" />
                                                </fieldset>
                                                <fieldset className="form-group floating-label-form-group mb-1">
                                                    <label htmlFor="user-password">Enter Password</label>
                                                    <input type="password" className="form-control" id="user-password" onChange={(e) => handleInputChange(e, setPassword)} onKeyDown={(e) => Enter(e)} placeholder="Enter Password" />
                                                </fieldset>
                                                <div className="form-group row">
                                                    {/* <div className="col-md-6 col-12 text-center text-sm-left">
                                                        <fieldset>
                                                            <input type="checkbox" id="remember-me" className="chk-remember" />
                                                            <label htmlFor="remember-me"> Remember Me</label>
                                                        </fieldset>
                                                    </div> */}
                                                    <div className="col-md-12 col-12 float-sm-left text-center text-sm-right"><a href="#" className="card-link">Forgot Password?</a></div>
                                                </div>
                                                <button type="button" onClick={onFinish} className="btn btn-outline-info btn-block"><i className="ft-unlock" /> Login</button>
                                            </form>
                                        </div>
                                        {/* <p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                            <span>New to Balance on Life ?</span>
                                        </p>
                                        <div className="card-body">
                                            <a href="register-with-bg.html" className="btn btn-outline-danger btn-block"><i className="ft-user" /> Register</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>


    )
}

export default EmployeeLoginComp